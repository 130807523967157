import React, { Component } from 'react';
import './question.less';
import getQuestion from '../../utils/getQuestion';
import Score from '../Score';
// import Countdown from 'react-countdown-clock';
import PropTypes from 'prop-types';
import Modal from '../Modal';
import CountdownTimer from '../CountdownTimer';

class Question extends Component {
    state = {
        singleQuestion: getQuestion(),
        highestScore: parseInt(localStorage.getItem('highScore'), 10) || 0,
        score: 0,
        modalOpen: false,
        modalText: ''
    };

    checkAnswer = answer => {
        // prettier-ignore
        return this.state.singleQuestion.correct === answer ? this.correctAnswer() : this.incorrectAnswer("WRONG");
    };

    correctAnswer = () => {
        // eslint-disable-next-line no-alert
        this.setState({
            score: this.state.score + 1,
            modalOpen: true,
            modalText: 'RIGHT!'
        });
    };

    incorrectAnswer = text => {
        if (this.state.score <= this.state.highestScore) {
            this.setState({
                score: 0,
                modalOpen: true,
                modalText: text
            });
        } else {
            this.setState(
                {
                    highestScore: this.state.score,
                    score: 0,
                    modalOpen: true,
                    modalText: text
                },
                () => {
                    this.highScore();
                }
            );
        }
    };

    handleClose = () => {
        this.setState({
            singleQuestion: getQuestion(),
            modalOpen: false,
            active: ""
        });
    };

    highScore = () => {
        localStorage.setItem('highScore', this.state.highestScore);
    };

    quitGame = async () => {
        if (this.state.score > this.state.highestScore) {
            await this.setState({
                highestScore: this.state.score
            });
            this.highScore();
            this.props.handleQuit();
        } else {
            this.props.handleQuit();
        }
    };

    render() {
        const { questionId, question, answers } = this.state.singleQuestion;
        return (
            // prettier-ignore
            <div key={questionId} className="one-question">
                <h3 className="question">{question}</h3>
                <div className="answers">
                    <button data-modal-target="#modal" id="answer1" className="answer-button button" onClick={() => this.checkAnswer(answers[0])}>{answers[0]}</button>
                    <button data-modal-target="#modal" id="answer2" className="answer-button button" onClick={() => this.checkAnswer(answers[1])}>{answers[1]}</button>
                    <button data-modal-target="#modal" id="answer3" className="answer-button button" onClick={() => this.checkAnswer(answers[2])}>{answers[2]}</button>
                    <button data-modal-target="#modal" id="answer4" className="answer-button button" onClick={() => this.checkAnswer(answers[3])}>{answers[3]}</button>
                </div>
                <div className="timer-and-score">
                    <div id="countdown" className="countdown">
                        {/* <Countdown
                            seconds={10}
                            color="#9db4c0"
                            alpha={0.9}
                            size={200}
                            paused={this.state.modalOpen}
                            onComplete={() => this.incorrectAnswer("Time's up")
                        /> */}
                        <CountdownTimer
                            startTimeInSeconds={10}
                            onComplete={() => this.incorrectAnswer("Time's up")}
                            paused={this.state.modalOpen}
                        />
                    </div>
                    <Score currentScore={this.state.score} highScore={this.state.highestScore} />
                </div>
                <Modal open={this.state.modalOpen} text={this.state.modalText} handleClose={() => this.handleClose()}/>
                <button data-testid="quit-button" className="quit-button button" onClick={this.quitGame}>Quit Game</button>
            </div>
        );
    }
}

Question.propTypes = {
    handleQuit: PropTypes.func
};

export default Question;
