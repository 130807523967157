import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './countdownTimer.less';

class CountdownTimer extends Component {
    componentWillUnmount() {
        clearInterval(this.state.timer);
    }

    state = {
        timeRemainingInSeconds: this.props.startTimeInSeconds,
        timer: setInterval(() => {
            this.decrementTimeRemaining();
        }, 1000)
    };

    decrementTimeRemaining = () => {
        if (!this.props.paused) {
            if (this.state.timeRemainingInSeconds > 0) {
                this.setState({
                    timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1
                });
            } else {
                this.props.onComplete();
                clearInterval(this.state.timer);
            }
        }
    };

    countdown = () => {
        return !this.props.paused ?
            {
                animation: `countdown-animation ${this.props
                    .startTimeInSeconds + 1}s linear`
            } : {
                animation: `countdown-animation ${this.state
                    .timeRemainingInSeconds + 1}s paused`
            };
    }

    render() {
        return (
            <div className="countdown-timer">
                <div className="countdown-timer__circle">
                    <svg>
                        <circle
                            r="60"
                            cx="70"
                            cy="70"
                            style={this.countdown()}
                        />
                    </svg>
                </div>
                <div className="countdown-timer__text">
                    {this.state.timeRemainingInSeconds}
                </div>
            </div>
        );
    }
}

CountdownTimer.propTypes = {
    startTimeInSeconds: PropTypes.number,
    onComplete: PropTypes.func,
    paused: PropTypes.bool
};

export default CountdownTimer;
