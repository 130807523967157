import React, { Component } from 'react';
import './Hamburger.less';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

class Hamburger extends Component {
    state={
        open: false
    }

    toggleMenu = () => {
        this.setState(prevState => ({
            open: !prevState.open
        }));
    }

    render() {
        return (
            <div className="hamburger-container" data-testid="hamburger-container">
                <div className="hamburger-menu" data-testid="hamburger-menu" onClick={this.toggleMenu}>
                    <div className={`line line-1 ${this.state.open ? "active" : ""}`} />
                    <div className="line line-2" />
                    <div className={`line line-3 ${this.state.open ? "active" : ""}`} />
                </div>
                <nav className={`navbar ${this.state.open ? "active" : ""}`}>
                    <ul className="nav-list">
                        {this.props.loginText === 'LOG IN' ? (
                            <div className="logged-out-group">
                                <Link to="/login" className="nav-item">
                                    <label className="nav-link">{this.props.loginText}</label>
                                </Link>
                                <Link to="/register" className="nav-item">
                                    <label className="nav-link">Register</label>
                                </Link>
                            </div>
                        ) : (
                            <div className="logged-in-group">
                                <Link to="/" className="nav-item" onClick={this.props.logout}>
                                    <label className="nav-link">{this.props.loginText}</label>
                                </Link>
                            </div>
                        )}
                    </ul>
                </nav>
            </div>
        );
    }
}

Hamburger.propTypes = {
    logout: PropTypes.func,
    loginText: PropTypes.string,
    user: PropTypes.object
};

export default withRouter(Hamburger);
