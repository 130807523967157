import React from 'react';
import ReactDOM from 'react-dom';
import './src/style/main.less';
import App from './src/App';
import Amplify from 'aws-amplify';

Amplify.configure({
    Auth: {
        // REQUIRED - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.REACT_APP_COGNITO_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID
        userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for (let registration of registrations) {
            registration.unregister();
        }
    });
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js').catch(err => {
            console.log('ERROR: ', err);
        });
    });
}
