import React, { Component } from 'react';
import { clearForm, handleRegister } from '../../utils/authenticationHandler';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import validateRegistrationForm from '../../utils/validateRegistrationForm';
import './register.less';

class Register extends Component {
    componentDidMount() {
        document.getElementById("password").value = "";
        document.getElementById("repeat_password").value = "";
    }

    state = {
        disabled: true
    };

    attemptValidate = async () => {
        const result = validateRegistrationForm();
        await result.error === undefined ?
            this.setState({
                disabled: false
            }) :
            this.setState({
                disabled: true
            });
    }

    attemptRegister = async (username, password, email, locale, birthdate, family_name, given_name, gender, form) => {
        try {
            const result = await handleRegister(username, password, email, locale, birthdate, family_name, given_name, gender, form);
            if (result.user) this.props.history.push('/');
        } catch (err) {
            alert('ERROR: ', err);
        }
    };

    render() {
        return (
            <form id="register-form" data-testid="register-form">
                <div className="register-header">
                    <h5 className="register-title" id="registerLongTitle">Register</h5>
                </div>
                <div className="register-body">
                    <label className="register-label">Username</label><br />
                    <input id="username" type="text" className="register-input form-control" data-testid="username-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Password</label><br />
                    <input id="password" type="password" className="register-input form-control" data-testid="password-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Repeat Password</label><br />
                    <input id="repeat_password" type="password" className="register-input form-control" data-testid="repeat-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">First name</label><br />
                    <input id="first_name" type="text" className="register-input form-control" data-testid="firstname-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Last name</label><br />
                    <input id="last_name" type="text" className="register-input form-control" data-testid="lastname-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Email</label><br />
                    <input id="email" type="email" className="register-input form-control" data-testid="email-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Date of birth</label><br />
                    <input id="dob" type="date" className="register-input form-control" data-testid="dob-input" onChange={this.attemptValidate} /><br />
                    <label className="register-label">Gender</label><br />
                    <select id="gender" type="text" className="register-input form-control" data-testid="gender-input" onChange={this.attemptValidate} >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                        <option value="other">Other</option>
                    </select><br />
                    <label className="register-label">Location</label><br />
                    <input id="location" type="text" className="register-input form-control" data-testid="location-input" onChange={this.attemptValidate} /><br />
                </div>
                <div className="register-footer">
                    <Link to="/login">
                        <button className="button register-link">Login</button>
                    </Link>
                    <Link to="/">
                        <button type="button" className="button" data-testid="close-button" onClick={() => clearForm('register-form')}>
                            Close
                        </button>
                    </Link>
                    <button
                        data-testid="register-button"
                        type="button"
                        className="button submit"
                        disabled={this.state.disabled}
                        onClick={() =>
                            this.attemptRegister(
                                document.getElementById("username").value,
                                document.getElementById("password").value,
                                document.getElementById("email").value,
                                document.getElementById("location").value,
                                document.getElementById("dob").value,
                                document.getElementById("last_name").value,
                                document.getElementById("first_name").value,
                                document.getElementById("gender").value,
                                "register-form"
                            )
                        }>
                        Register
                    </button>
                </div>
            </form>
        );
    }
}

Register.propTypes = {
    loginCallback: PropTypes.func,
    register: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(Register);
