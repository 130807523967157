import * as Joi from '@hapi/joi';

export const submitCodeSchema = Joi.object({
    oneTimeCode: Joi.string()
        .min(6)
        .max(6)
        .pattern(/^[0-9]{6,6}$/)
        .required()
        .error(new Error("code must be exactly 6 numeric characters")),
    password: Joi.string()
        .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        .required()
        .error(new Error("password must contain at least one uppercase and one lowercase letter, one number and one special character")),
    repeatPassword: Joi.ref('password')
});
