import { Auth } from 'aws-amplify';

export const handleLogin = async (username, password) => {
    let loginResult;
    try {
        loginResult = await Auth.signIn(username, password);
    } catch (err) {
        loginResult = err;
    }
    return loginResult;
};

export const checkSession = async () => {
    return await Auth.currentAuthenticatedUser();
};

export const currentSession = async () => {
    await Auth.currentSession();
};

export const forgotPasswordSubmit = async (email, code, password) => {
    await Auth.forgotPasswordSubmit(email, code, password);
};

export const handleLogout = async () => {
    await Auth.signOut();
};

export const clearForm = form => {
    try {
        document.getElementById(form).reset();
    } catch (err) {
        console.log('ERROR: ', err, 'FORM: ', form);
    }
};

export const forgotPassword = async email => {
    return await Auth.forgotPassword(email);
};

export const handleRegister = async (username, password, email, locale, birthdate, family_name, given_name, gender, form) => {
    try {
        const signUpResponse = await Auth.signUp({
            username,
            password,
            attributes: {
                email,
                locale,
                birthdate,
                gender,
                family_name,
                given_name
            }
        });

        clearForm(form);
        return signUpResponse;
    } catch (err) {
        alert(err.message);
        return err;
    }
};
