import React from 'react';
import './score.less';
import PropTypes from 'prop-types';

const Score = props => {
    const { currentScore, highScore } = props;
    return (
        <div className="score">
            <div className="current-score">
                <label className="score-text">Current Score</label>
                <label className="score-number">{currentScore}</label>
            </div>
            <div className="high-score">
                <label className="score-text">High Score</label>
                <label className="score-number">{highScore}</label>
            </div>
        </div>
    );
};

Score.propTypes = {
    currentScore: PropTypes.number,
    highScore: PropTypes.number,
    timesUp: PropTypes.func
};

export default Score;
