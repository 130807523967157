import React, { Component } from 'react';
import LandingPage from './components/LandingPage';
import QuizPage from './components/QuizPage';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import SubmitResetCode from './components/SubmitResetCode';
import { Router, Route, Switch } from 'react-router-dom';
import history from './utils/history';
import { handleLogout, checkSession, currentSession } from './utils/authenticationHandler';

class App extends Component {
    componentDidMount = async () => {
        try {
            await currentSession();
            this.setState({
                isAuthenticated: true
            });
            const user = await checkSession();
            this.setState({
                user: user,
                loginText: 'LOG OUT',
                isAuthenticating: false
            });
        } catch (err) {
            return err;
        }
        this.setState({
            isAuthenticating: false
        });
    };

    state = {
        isAuthenticated: false,
        user: null,
        isAuthenticating: true,
        orientation: screen.orientation.type,
        loginText: 'LOG IN',
        email: ''
    };

    loginCallback = () => {
        this.setState({
            loginText: 'LOG OUT'
        });
    };

    forgotPasswordCallback = email => {
        this.setState({
            email: email
        });
    };

    logout = async () => {
        try {
            await handleLogout();
            this.setState({ loginText: 'LOG IN' });
        } catch (err) {
            alert(err);
        }
    };

    render() {
        window.addEventListener('orientationchange', () => {
            this.setState({
                orientation: screen.orientation.type
            });
        });
        return this.state.isAuthenticating &&
            <Router history={history}>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={() => (
                            <LandingPage
                                loginText={this.state.loginText}
                                logout={this.logout}
                                user={this.state.user}
                            />
                        )}
                    />
                    <Route
                        path="/quiz"
                        render={() => <QuizPage />}
                    />
                    <Route
                        path="/login"
                        render={() => <Login loginCallback={username => this.loginCallback(username)} />}
                    />
                    <Route
                        path="/register"
                        render={() => <Register />}
                    />
                    <Route
                        path="/forgotpassword"
                        render={() => (
                            <ForgotPassword forgotPasswordCallback={email => this.forgotPasswordCallback(email)} />
                        )}
                    />
                    <Route
                        path="/submitresetcode"
                        render={() => (
                            <SubmitResetCode
                                email={this.state.email}
                                passwordResetCallback={this.passwordResetCallback}
                            />
                        )}
                    />
                </Switch>
            </Router>;
    }
}

export default App;
