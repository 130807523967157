import React, { Component } from 'react';
import { clearForm, forgotPasswordSubmit } from '../../utils/authenticationHandler';
import PropTypes from 'prop-types';
import './SubmitResetCode.less';
import { Link, withRouter } from 'react-router-dom';
import validateSubmitResetForm from '../../utils/validateSubmitResetForm';

class SubmitResetCode extends Component {
    state = {
        disabled: true
    };

    resetPassword = async (email, code, password, form) => {
        try {
            await forgotPasswordSubmit(email, code, password);
            alert('Password changed');
            this.props.history.push('/');
            clearForm(form);
        } catch (err) {
            console.log(err);
        }
    };

    attemptValidate = async () => {
        const result = validateSubmitResetForm();
        await result.error === undefined ?
            this.setState({
                disabled: false
            }) :
            this.setState({
                disabled: true
            });
    };

    render() {
        return (
            <form id="submit-code-form">
                <h5 className="submit-code-title" id="submit-codeTitle">
                    Forgot Password
                </h5>
                <div className="submit-code-body">
                    <label className="submit-code-label">One-time code</label><br />
                    <input
                        name="one-time-code"
                        id="one-time-code"
                        type="text"
                        data-testid="one-time-code"
                        className="submit-code-input form-control"
                        onChange={this.attemptValidate}
                    />
                    <br />
                    <label className="submit-code-label">New password</label><br />
                    <input
                        name="password"
                        id="password"
                        type="password"
                        className="submit-code-input form-control"
                        onChange={this.attemptValidate}
                    />
                    <br />
                    <label className="submit-code-label">Repeat password</label><br />
                    <input
                        name="repeat-password"
                        id="repeat-password"
                        type="password"
                        className="submit-code-input form-control"
                        onChange={this.attemptValidate}
                    />
                    <br />
                </div>
                <div className="submit-code-footer">
                    <button disabled={this.state.disabled} type="button" className="button" data-testid="submit-reset" onClick={() => this.resetPassword(
                        this.props.email,
                        document.getElementById("one-time-code").value.toString(),
                        document.getElementById("password").value.toString(),
                        "submit-code-form"
                    )}>
                        Submit
                    </button>
                    <Link to="/">
                        <button type="button" className="button" data-testid="clearform" onClick={() => clearForm("submit-code-form")}>
                            Close
                        </button>
                    </Link>
                </div>
            </form>
        );
    }
}

SubmitResetCode.propTypes = {
    passwordResetCallback: PropTypes.func,
    email: PropTypes.string,
    history: PropTypes.object
};

export default withRouter(SubmitResetCode);
