import React, { Component } from 'react';
import { clearForm, handleLogin } from '../../utils/authenticationHandler';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { loginSchema } from '../schemas/loginSchema';
import './login.less';

class LoginForm extends Component {
    state = {
        disabled: true
    }

    handleLoginAttempt = async (username, password) => {
        const result = await handleLogin(username, password);
        let jwtToken;

        try {
            if (!result.code) {
                jwtToken = result.signInUserSession.idToken.jwtToken;
            }

            if (jwtToken) {
                this.props.loginCallback(username);
                this.props.history.push('/');
            } else {
                alert('Incorrect username or password');
            }
        } catch (err) {
            alert('ERROR: ', err);
        }
    };

    validateLogin = () => {
        if (loginSchema.validate({
            username: document.getElementById("username").value.toString(),
            password: document.getElementById("password").value.toString()
        }).error === undefined) {
            this.setState({
                disabled: false
            });
        } else {
            this.setState({
                disabled: true
            });
        }
    }

    render() {
        return (
            <form id="login-form" data-testid="login-form">
                <h5 className="login-title" id="loginTitle">
                    Log in
                </h5>
                <div className="login-body">
                    <label className="login-label">Username</label><br />
                    <input data-testid="username-input" name="username" id="username" type="text" className="login-input form-control" onChange={this.validateLogin} />
                    <br />
                    <label className="login-label">Password</label><br />
                    <input data-testid="password-input" name="password" id="password" type="password" className="login-input form-control" onChange={this.validateLogin} />
                    <br />
                </div>
                <div className="login-footer">
                    <Link to="/register">
                        <button type="button" className="button register-link">
                            Register
                        </button>
                    </Link>
                    <Link to="/">
                        <button data-testid="clearform" type="button" className="button" onClick={() => clearForm('login-form')}>
                            Close
                        </button>
                    </Link>
                    <button
                        data-testid="login-button"
                        type="button"
                        disabled={this.state.disabled}
                        className="button submit"
                        onClick={() =>
                            this.handleLoginAttempt(
                                document.getElementById('username').value,
                                document.getElementById('password').value
                            )
                        }
                    >
                        Login
                    </button>
                    <br />
                    <Link to="/forgotpassword">
                        <button type="button" className="button register-link">
                            Forgot password?
                        </button>
                    </Link>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    loginCallback: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(LoginForm);
