import React from 'react';
import './modal.less';
import PropTypes from 'prop-types';

const Modal = props => {
    const { text, handleClose, open } = props;

    return (
        <div>
            <div className={`modal ${open ? "active" : ""}`} id="modal">
                <div className="modal-content">
                    <p className="text">{text}</p>
                    <button id="close-button" className="close-button" onClick={handleClose}>NEXT QUESTION</button>
                </div>
            </div>
            <div className={`overlay ${open ? "active" : ""}`} />
        </div>
    );
};


Modal.propTypes = {
    text: PropTypes.string,
    handleClose: PropTypes.func,
    open: PropTypes.bool
};

export default Modal;
