import { registrationSchema } from '../components/schemas/registrationSchema';

const validateRegistrationForm = () => {
    return registrationSchema.validate({
        username: document.getElementById("username").value.toString(),
        password: document.getElementById("password").value.toString(),
        repeat_password: document.getElementById("repeat_password").value.toString(),
        email: document.getElementById("email").value.toString(),
        birthdate: document.getElementById("dob").value.toString(),
        last_name: document.getElementById("last_name").value.toString(),
        first_name: document.getElementById("first_name").value.toString(),
        gender: document.getElementById("gender").value.toString(),
        location: document.getElementById("location").value.toString()
    });
};

export default validateRegistrationForm;
