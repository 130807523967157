import * as Joi from '@hapi/joi';

export const registrationSchema = Joi.object({
    username: Joi.string()
        .min(3)
        .max(30)
        .required()
        .error(new Error("username must be at least three characters")),
    password: Joi.string()
        .pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/)
        .required()
        .error(new Error("password must contain at least one uppercase and one lowercase letter, one number and one special character")),
    repeat_password: Joi.ref('password'),
    first_name: Joi.string().max(30).required(),
    last_name: Joi.string().max(30).required(),
    email: Joi.string()
        .email({ minDomainSegments: 2, tlds: false })
        .required(),
    birthdate: Joi.date()
        .min('01/01/1900')
        .required(),
    gender: Joi.string().required(),
    location: Joi.string().required()
});
