import React, { Component } from 'react';
import './LandingPage.less';
import Image from '../../icons/icon-512x512.png';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Hamburger from '../Hamburger';

class LandingPage extends Component {
    render() {
        return (
            <div className="full-landing-page" data-testid="landing-page">
                <img src={Image} className="logo" />
                <div className="hamburger-menu">
                    <Hamburger loginText={this.props.loginText} logout={this.props.logout} user={this.props.user} />
                </div>
                <div className="button-group">
                    <Link to="/quiz">
                        <button className="buttons button">START QUIZ</button>
                    </Link>
                </div>
            </div>
        );
    }
}

LandingPage.propTypes = {
    logout: PropTypes.func,
    loginText: PropTypes.string,
    user: PropTypes.object
};

export default withRouter(LandingPage);
