// Connect to questions DB
import AllQuestions from '../../questionsDb.csv';

const getQuestion = () => {
    // Pull one random question from DB
    const size = AllQuestions.length;
    const randomQuestionNumber = Math.floor(Math.random() * size);
    let singleQuestion = AllQuestions[randomQuestionNumber];

    // Build a 'question' object with {questionId, question, 4 answers(including correct)[], correct answer}
    const question = {
        questionId: singleQuestion.QuestionId,
        question: singleQuestion.Question,
        answers: [
            singleQuestion.Correct,
            singleQuestion.Wrong1,
            singleQuestion.Wrong2,
            singleQuestion.Wrong3
        ],
        correct: singleQuestion.Correct,
        rating: singleQuestion.Rating
    };

    // Randomise order of answers
    const shuffle = answers => {
        for (
            let currentIndex = answers.length - 1;
            currentIndex > 0;
            currentIndex--
        ) {
            const randomIndex = Math.floor(Math.random() * currentIndex);
            const temp = answers[currentIndex];
            answers[currentIndex] = answers[randomIndex];
            answers[randomIndex] = temp;
        }
        return answers;
    };

    question.answers = shuffle(question.answers);

    return question;
};

export default getQuestion;
