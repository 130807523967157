import React, { Component } from 'react';
import Image from '../../icons/icon-152x152.png';
import Question from '../Question';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import './quizPage.less';

class QuizPage extends Component {
    state = {};
    render() {
        return (
            <div className="main" data-testid="quiz-page">
                <div className="main-title">
                    <img src={Image} className="quiz-logo" />
                    <h1 className="title-name">alqemy</h1>
                </div>
                <Question handleQuit={
                    () => this.props.history.push('/')
                } />
            </div>
        );
    }
}

QuizPage.propTypes = {
    history: PropTypes.object
};

export default withRouter(QuizPage);
