import * as Joi from '@hapi/joi';

export const loginSchema = Joi.object({
    username: Joi.string()
        .min(3)
        .max(50)
        .required(),
    password: Joi.string()
        .min(8)
        .max(120)
        .required()
});
