import React, { Component } from 'react';
import { clearForm, forgotPassword } from '../../utils/authenticationHandler';
import PropTypes from 'prop-types';
import './forgotPassword.less';
import { Link, withRouter } from 'react-router-dom';
import { emailSchema } from '../schemas/emailSchema';

class ForgotPassword extends Component {
    state = {
        disabled: true
    };

    sendEmail = async () => {
        try {
            const email = document.getElementById("email").value.toString();
            await forgotPassword(email);
            alert('If the email you entered was found in our database a password reset email has been sent');
            clearForm('forgot-password-form');
            this.props.history.push("/submitresetcode");
            this.props.forgotPasswordCallback(email);
        } catch (err) {
            alert(err.message);
        }
    };

    validateEmail = () => {
        if (emailSchema.validate({
            email: document.getElementById("email").value.toString()
        }).error === undefined) {
            this.setState({
                disabled: false
            });
        } else {
            this.setState({
                disabled: true
            });
        }
    }

    render() {
        return (
            <form id="forgot-password-form" data-testid="forgot-password">
                <h5 className="forgot-password-title" id="forgot-passwordTitle">
                    Forgot Password
                </h5>
                <div className="forgot-password-body">
                    <label className="forgot-password-label">Enter the email associated with your account and a reset link will be emailed to you</label><br />
                    <input
                        name="email"
                        id="email"
                        type="text"
                        className="forgot-password-input"
                        data-testid="email-input"
                        onChange={this.validateEmail}
                    />
                    <br />
                </div>
                <div className="forgot-password-footer">
                    <div className="forgot-password-footer">
                        <button disabled={this.state.disabled} type="button" className="button" data-testid="forgot-button" onClick={this.sendEmail}>
                            Submit
                        </button>
                        <Link to="/">
                            <button type="button" className="button" data-testid="close-button" onClick={() => clearForm('forgot-password-form')}>
                                Close
                            </button>
                        </Link>
                    </div>
                </div>
            </form>
        );
    }
}

ForgotPassword.propTypes = {
    forgotPasswordCallback: PropTypes.func,
    history: PropTypes.object
};

export default withRouter(ForgotPassword);
