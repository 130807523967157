import { submitCodeSchema } from '../components/schemas/submitCodeSchema';

const validateSubmitResetForm = () => {
    return submitCodeSchema.validate({
        oneTimeCode: document.getElementById("one-time-code").value.toString(),
        password: document.getElementById("password").value.toString(),
        repeatPassword: document.getElementById("repeat-password").value.toString()
    });
};

export default validateSubmitResetForm;
